import React from 'react';
import { motion } from 'framer-motion';
import { SplitText } from '@cyriacbr/react-split-text';
import { useInView } from 'react-intersection-observer';

const AnimatedSplitTitle = ({ children, delay = 0 }) => {
  const [ref, inView] = useInView({
    threshold: 0,
  });
  // const controls = useAnimation();

  // useEffect(() => {
  //   if (inView) {
  //     controls.start('animate');
  //   }
  // }, [inView]);

  return (
    <div ref={ref}>
      <SplitText
        LetterWrapper={({ children, countIndex }) => {
          return (
            <div
              style={{
                position: 'relative',
                display: 'inline-block',
              }}
            >
              <span style={{ opacity: 0 }}>{children}</span>
              <span
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
              >
                <motion.span
                  animate={inView && 'animate'}
                  initial='initial'
                  variants={{
                    animate: {
                      x: '0%',
                      scaleX: 1,
                      opacity: 1,
                      transition: {
                        type: 'spring',
                        damping: 30,
                        mass: 0.75,
                        stiffness: 100,
                        delay: 0.04 * countIndex,
                      },
                    },
                    initial: {
                      x: '50%',
                      scaleX: 1,
                      opacity: 0,
                      transition: {
                        type: 'spring',
                        damping: 30,
                        mass: 0.75,
                        stiffness: 100,
                        delay: 0.04 * countIndex,
                      },
                    },
                  }}
                  style={{
                    display: 'inline-block',
                    transformOrigin: '0% 50%',
                  }}
                >
                  {children}
                </motion.span>
              </span>
            </div>
          );
        }}
      >
        {children}
      </SplitText>
    </div>
  );
};

export default AnimatedSplitTitle;
