import styled from 'styled-components';
import React, { useEffect } from 'react';
import { globalHistory } from '@reach/router'
import Breadcrumb from 'components/Breadcrumb';
import Typography from 'components/UI/Typography';
import { Link } from 'gatsby';
import imgSrc from 'static/images/fond_texture_01.png';
import AnimatedSplitTitle from 'components/AnimatedSplitTitle';
import gtmEvents from '../utils/functions/gtmFunctions.js';

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background: ${(props) => props.theme.colors.primary};
  position: relative;
`;

const Title = styled(Typography)`
  color: ${(props) => props.theme.colors.blue};
  font-size: 201px !important;
  font-weight: 700 !important;
  margin-bottom: 55px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 150px !important;
    margin-bottom: 40px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    font-size: 80px !important;
  }
`;

const Cta = styled(Link)`
  font-size: 20px;
  color: ${(props) => props.theme.colors.blue};
  border: 1px solid ${(props) => props.theme.colors.blue};
  padding: 15px 20px;
`;

const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, calc(-50% - 100px));
  width: 100%;
  padding: 0 20px;
  z-index: 1;
`;

const BackgroundImage = styled.img`
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
`;

const NotFound = (props) => {
  useEffect(() => {
      gtmEvents.sendVirtualPageView("Erreur 404", globalHistory.location.href.href, globalHistory.location.pathname);
  }, []);
  return (
    <>
      <Container>
        <Breadcrumb />
        <Content>
          <Title elementTheme='h1'>
            <AnimatedSplitTitle>Oups !</AnimatedSplitTitle>
          </Title>
          <Cta to='/'>
            Erreur 404 | Cette page est introuvable ou n’existe plus.
          </Cta>
        </Content>
        <BackgroundImage src={imgSrc} />
      </Container>
    </>
  );
};

export default NotFound;
